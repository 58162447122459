<template>
<div>

  <v-card elevation="0"   style="padding: 15px 30px;height: 100%;" >
  </v-card>

  <vcl-facebook  v-if="!pageloading" style="margin: 20px"></vcl-facebook>
  <v-card v-else elevation="0" style="padding: 15px 30px;height: 100%;" >
    <v-row v-if="$store.getters.auth.loggedIn">
      <v-col cols="6" color="border-bottom: solid 1px #ccc">
        <router-link to="/" class="rlink"><v-icon >mdi-close</v-icon><span>برگشت به قبل</span></router-link>
      </v-col>
      <v-col cols="2">

      </v-col>
      <v-col cols="4">
        <p @click="dialog3 = true" class="mb-0 mt-0" >
          <v-icon>mdi-logout-variant</v-icon>&nbsp;
          <span>خروج</span>
        </p>
      </v-col>

      <v-card
                class="mx-auto"
                style="width: 100%;text-align: right;"

        >
          <v-card-subtitle style="color:#0e0e0e;background-color:#6a737d; font-weight: bolder;text-align: right;width: 100%;">
            <span color="red" >مشخصات شما</span>
          </v-card-subtitle>

          <v-card-actions style="text-align: right;flex-direction: row-reverse !important;" >

            <v-spacer></v-spacer>



            <div  style="text-align: right;font-weight: bold"  >

              <div v-if="tell">
                <span class="font-medium">نام و نام خانوادگی</span>: 
                <span >{{ $store.getters.auth.data.namefamily  }}</span><br/>
              </div>


              <div v-if="tell">
                <span class="font-medium">شماره ثبت شده در سیستم</span>: 
                <span >{{tell}}</span><br/>
              </div>
              <div v-if="mdaraee>0">
                <span class="font-medium">مبلغ  صندوق دارایی</span>: 
                <v-badge class="font-small font-weight-bold " style="text-align: center;"
                         color="green" :content="helperNumberFormat( mdaraee  )+ ' تومان '"  ></v-badge><br/>

              </div>
              <div v-else>
                <span class="font-medium">مبلغ  صندوق دارایی</span>: 
                <v-badge class="font-small font-weight-bold " style="text-align: center;"
                         color="red" :content=" ' صفر '"  ></v-badge><br/>

              </div>
              <div v-if="mjayeze>0">
                <span class="font-medium">مبلغ صندوق جایزه</span>: 
                <v-badge class="font-small font-weight-bold " style="text-align: center;"
                         color="green" :content="helperNumberFormat( mjayeze  )+ ' تومان '"  ></v-badge><br/>

              </div>
              <div v-else>
                <span class="font-medium">مبلغ  صندوق جایزه</span>: 
                <v-badge class="font-small font-weight-bold " style="text-align: center;"
                         color="red" :content=" ' صفر '"  ></v-badge><br/>

              </div>
              <div v-if="mporsant>0">
                <span class="font-medium">مبلغ صندوق پورسانت</span>: 
                <v-badge class="font-small font-weight-bold " style="text-align: center;"
                         color="green" :content="helperNumberFormat( mporsant )+ ' تومان '"  ></v-badge><br/>

              </div>

            </div>

          </v-card-actions>
        <br>
        <v-btn  @click="$router.push({path:'/profileedit'});" :loading="loading" depressed color="#e3a232" height="50" style="width: 100%"> ویرایش مشخصات </v-btn>
        <br>
        </v-card><br>
      <v-divider  style="margin:5px;"></v-divider>
      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"

      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#2077d3; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" >هدیه به سایت روش اول (روش پرفکت مانی) </span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="font-weight: bold;text-align: justify"  >
<span class="font-medium">

  در این روش شما میتوانید یک ووچر پرفکت مانی از سایتهایی که در صفحه بعد برای شما در نظر گرفته شده است تهیه نمایید و آن ووچر را در قسمت مربوط وارد نمایید. بعد از تایید مبلغتان مستقیما در کاربری شارژ خواهد شد.<br>
</span>
            <v-btn  @click="$router.push({path:'/perfectmoney'});" :loading="loading" depressed color="#e3a232" height="50" style="width: 100%"> ورود به صفحه روش پرفکت مانی </v-btn>

          </div>

        </v-card-actions>

      </v-card>
      <v-divider style="margin:5px;"></v-divider>
      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"
          v-if="1==0"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#5cd320; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" > هدیه به سایت روش سوم (روش رمز ارزی) </span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="font-weight: bold;text-align: justify"  >
<span class="font-medium">
            در این روش شما به کمک رمزارزها هدیه مورد نظر خودتان را برای ما ارسال مینمایید اینکار بدون دردسر انجام میشود و بعد از تایید تراکنش سریعا حساب کاربریتان تایید میشود.
</span>
          </div>

        </v-card-actions>
        <br>
        <v-btn  @click="$router.push({path:'/crypto'});" :loading="loading"  color="#e3a232" height="50" style="width: 100%"> ورود به صفحه افزایش موجودی </v-btn>

      </v-card>









      <v-divider style="margin:5px;"></v-divider>
      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"

      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#5cd320; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" > هدیه به سایت روش سوم (روش کارت به کارت) </span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="font-weight: bold;text-align: justify"  >
          <span class="font-medium">
            در این روش شما باید هر بار قبل واریز مبلغ به این قسمت آمده و کارت و مبلغ واریز را مشخص کنید در غیر اینصورت چیزی تایید نمیشود.
         </span>
          </div>

        </v-card-actions>
        <br>
        <v-btn  @click="$router.push({path:'/card'});" :loading="loading"  color="#e3a232" height="50" style="width: 100%"> گرفتن کارت ما </v-btn>

      </v-card>

    </v-row>
  </v-card>
  <br>
  <vcl-facebook style="margin: 20px"  v-if="!pageloading"></vcl-facebook>
  <v-row v-else justify="center">
    <v-dialog v-model="dialog3" persistent  max-width="390" >

      <v-card>
        <v-card-title style="font-size: 16px;word-break: normal">
         آیا برای خروج از حساب کاربری مطمن هستید؟
        </v-card-title>
        <v-card-text>این عملیات غیر قابل بازگشت میباشد و برای دسترسی دوبار باید لاگین کنید</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="green darken-1" text @click="dialog3 = false">
            خیر منصرف شده
          </v-btn>
          <v-btn color="red darken-1" text @click="logoutApp">
            بله مطمنم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


</div>

</template>
<script>
  import {mapGetters} from 'vuex';
  import {mapMutations} from 'vuex';
  import axios from "axios";
  import { VclFacebook, VclInstagram } from 'vue-content-loading';

  export default {
    name: 'login',
    components:{
      VclFacebook,
      VclInstagram,
      mapGetters,mapMutations},
    data() {
      return {

        loading:false,
        dialog:false,
        dialog2:false,
        dialog3:false,
        mdaraee:0,
        mjayeze:0,
        mporsant:0,
        tell:"0",
        pageloading:false,

      }
    },
    mounted() {
      let self = this;
      this.getInfo();
      //self.pageloading=true;
      //this.postList();
    },
    computed:{
      ...mapGetters(['auth','appToken','baseUrl']),

    },
    methods:{
      ...mapMutations(['logout']),

      getInfo(){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

        axios.post(this.baseUrl+'/mojodi.aspx', formData, config)
            .then(function (r) {

              if(r.data.data.result=="1"){
                self.mdaraee=r.data.data.daraee;
                self.mjayeze=r.data.data.jayeze;
                self.mporsant=r.data.data.porsant;
                self.tell=r.data.data.tell;
                self.pageloading=true;
              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
              self.pageloading=true;
            });

      },
      logoutApp(){
        this.logout();
        this.$router.push({path:'/'})

      },

    },
    watch:{
     /* file_list(v){
        this.param.avatar = URL.createObjectURL(v);
        this.saveAvatar();
      }*/
    }
  }
</script>

<style>
  .header-r{
    text-align: center;
  }
  .header-l p{

  }
  .header-l p span.subtitle{
    font-weight: bold;
    font-size: 13px;
  }

  #gallery {
    margin-top: 10px;
  }
  .fm-preview-img{
    width:  100%;
    height: 100px;
    border-radius: 5px;
    position: relative;
    vertical-align: middle;
    margin-bottom: 5px;
    background: #DDD;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }
  .fm-preview-img img{
    max-width: 100px;
    height: 100%;
    max-height: 100px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .text-error{
    color: coral;
    font-weight: bold;
    line-height: 1;
    font-size: 13px;
  }

  .fileBselectRows{
    width: 100%;
    border:dashed 2px #0084ff;
    border-radius: 5px;
    height: 105px;
    overflow: hidden;
    position: relative;
    z-index: 98;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
  }
  .fileBrows input{
    width: 100%;
    height: 100%;
    border:solid 5px;
    opacity: 0;
    cursor: pointer;
    display: block;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fileBrows span{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    z-index: 9;
    position: absolute;
    display: block;
    color: #0084ff;
    left: 0;
    top:calc(30% - 20px);
  }
  .fileBrows span i{
    font-size: 30px;
    font-style: normal;
  }

</style>







